window.changeCaseOptions = function () {
  return {
    casechange_title_case_minors: [
      // waiting on list of words from client https://switchbox.teamwork.com/#/tasks/25762771
      // 'at', 'by', 'in', 'of', 'on', 'up', 'to', 'en', 're', 'vs',
      // 'but', 'off', 'out', 'via', 'bar', 'mid', 'per', 'pro', 'qua', 'til',
      // 'from', 'into', 'unto', 'with', 'amid', 'anit', 'atop', 'down', 'less', 'like', 'near', 'over', 'past', 'plus', 'sans', 'save', 'than', 'thru', 'till', 'upon',
      // 'for', 'and', 'nor', 'but', 'or', 'yet', 'so', 'an', 'a', 'some', 'the'
    ],
  };
};

window.customToolbar = function (includeCover = false) {
  let customToolbarSections = [
    "formula",
    "asset",
    "page",
    "changelog",
    "legend",
    "boxes",
    "adult_meds",
    "pediatric_meds",
    "templates",
    "page_legend",
    "space",
    "insert_space",
    "mybutton",
  ];
  if (includeCover) {
    customToolbarSections.push("cover");
  }

  return customToolbarSections.join("|");
};

// function extendedValidElements() {
//   console.log('extended')
//   return {
//     extended_valid_elements : "iframe[align<bottom?left?middle?right?top|class|frameborder|height|id"
//       +"|longdesc|marginheight|marginwidth|name|scrolling<auto?no?yes|src|style"
//       +"|title|width],"
//   }
// }

window.filePickerCallBack = function (cb, _value, _meta) {
  var input = document.createElement("input");
  input.setAttribute("type", "file");
  input.onchange = function () {
    var file = this.files[0];

    var reader = new FileReader();
    reader.onload = function () {
      var id = "blobid" + new Date().getTime();
      var blobCache = tinymce.activeEditor.editorUpload.blobCache;
      var base64 = reader.result.split(",")[1];
      var blobInfo = blobCache.create(id, file, base64);
      blobCache.add(blobInfo);

      cb(blobInfo.blobUri(), { title: file.name });
    };
    reader.readAsDataURL(file);
  };

  input.click();
};

window.imagesUploadHandler = function (url) {
  return (blobInfo, success, _failure) => {
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", url);

    xhr.onload = function () {
      var json = JSON.parse(xhr.responseText);
      success(json.url);
    };

    formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  };
};

// initialize tinymce
window.initTinyMce = function (opts) {
  return Object.assign(
    {},
    tinyMceOptions(opts),
    powerPasteOptions(),
    changeCaseOptions()
    // extendedValidElements()
  );
};

window.insertFormula = function (editor, formulaId, formula, note) {
  editor.insertContent("!![formula_id=" + formulaId + "] - " + formula + "!!");
  editor.insertContent("%%" + note + "%%");
};

// normal wysiwyg toolbar
window.normalToolbar = function () {
  return [
    "paste",
    "formatselect",
    "bold italic strikethrough forecolor backcolor casechange",
    "undo redo",
    "link pageembed",
    "alignleft aligncenter alignright alignjustify",
    "numlist bullist checklist outdent indent",
    "removeformat code pastetext",
  ].join("|");
};

window.pastePostProcess = function (plugin, args) {
  args.node.innerHTML = args.node.innerHTML.replace(
    /style=(\'|\")([ -0-9a-zA-Z:]*[ 0-9a-zA-Z;]*)*\1/g,
    ""
  );
};

window.plugins = function () {
  return [
    "link",
    "searchreplace",
    "autolink",
    "visualblocks",
    "visualchars",
    "fullscreen",
    "link",
    "media",
    "template",
    "codesample",
    "table",
    "charmap",
    "hr",
    "pagebreak",
    "nonbreaking",
    "anchor",
    "toc",
    "insertdatetime",
    "advlist",
    "lists",
    "imagetools",
    "textpattern",
    "help",
  ];
};

window.pluginsToUse = function () {
  return plugins().concat(proPlugins()).join(" ");
};

window.powerPasteOptions = function () {
  return {
    // https://www.tiny.cloud/docs/plugins/powerpaste/#configurationoptions
    paste_as_text: false, // true false
    powerpaste_word_import: "clean", // 'clean' 'merge' 'prompt'
    powerpaste_html_import: "merge", // 'clean' 'merge' 'prompt'
    powerpaste_allow_local_images: true, // true false
    powerpaste_block_drop: true, // true false
    powerpaste_clean_filtered_inline_elements:
      "strong, em, b, i, u, strike, sup, sub, font", // A comma-separated string of HTML elements to remove.
    powerpaste_keep_unsupported_src: true, // true false
    // https://www.tiny.cloud/docs/plugins/powerpaste/#configurationoptions
    // paste_preprocess
    // paste_postprocess:                         pastePostProcess
    // function (plugin, args) {
    //   args.node.innerHTML = args.node.innerHTML.replace(/style=(\'|\")([ -0-9a-zA-Z:]*[ 0-9a-zA-Z;]*)*\1/g, '');
    // }
  };
};

window.proPlugins = function () {
  // https://www.tiny.cloud/docs/ > Plugins
  return [
    // 'a11ychecker',
    "advcode", // can not use with the 'code' plugin
    // 'advtable',
    "casechange",
    "checklist",
    // 'tinycomments',
    // 'tinydrive',
    // 'mediaembed',
    // 'formatpainter',
    // 'linkchecker',
    // 'mentions',
    "pageembed",
    // 'permanentpen',
    "powerpaste", // can not use with the 'paste' plugin
    "tinymcespellchecker",
  ];
};

window.setup = function (opts) {
  return (editor) => {
    // formula button
    editor.ui.registry.addButton("formula", {
      text: "Medication",
      tooltip: "Insert forumula",
      onAction: function () {
        $(`.formula-modal-${opts.uuid}`).modal("show");
        $(`.formula-modal-${opts.uuid} .save`).click(function (e) {
          const editorContent = editor.getContent();

          // loop over the checked formulas and insert them into the editor
          $(`.formula-modal-${opts.uuid} input[type='checkbox']:checked`).each(
            function (index, item) {
              insertFormula(
                editor,
                $(this).val(),
                $(this).data("formula-label"),
                $(this).data("formula-note")
              );
            }
          );

          if (editorContent !== editor.getContent()) {
            $(".formula-modal input[type='checkbox']").prop("checked", false);
            $(`.formula-modal-${opts.uuid}`).modal("hide");
          }
        });
      },
    });

    editor.ui.registry.addButton("boxes", {
      text: "Boxes",
      tooltip: "Insert Box",
      onAction: function () {
        $(`.box-modal-${opts.uuid}`).modal("show");
      },
    });
    $(`.box-modal-${opts.uuid} .save`).on("click", function (e) {
      title = $(`#box_title_${opts.uuid}`);
      id = $(`#persona_select_${opts.uuid}`);

      if (title.val() != "") {
        title_box =
          '<div class="persona_header" id="persona_' +
          id.val() +
          '"><div>' +
          title.val() +
          "</div></div>";
      } else {
        title_box = "";
      }
      const editorContent = editor.getContent();
      if (typeof id.val() !== "undefined") {
        editor.insertContent(
          '<div class="drug_box">' +
            title_box +
            '<div class="persona_body"></div></div><p></p>'
        );
        if (editorContent !== editor.getContent()) {
          $(`#box_title_${opts.uuid}`).val("");
          $(`#persona_select_${opts.uuid}`).val("");
          $(`.box-modal-${opts.uuid}`).modal("hide");
        }
      }
    });

    editor.ui.registry.addButton("templates", {
      text: "Templates",
      tooltip: "Insert Template",
      onAction: function () {
        $(`.template-modal-${opts.uuid}`).modal("show");
      },
    });
    $(`.template-modal-${opts.uuid} .save`).on("click", function (e) {
      id = $(`#template_select_${opts.uuid}`);

      //make an ajax call to get all the html and use it to poplate the editor

      const editorContent = editor.getContent();
      if (typeof id.val() !== "undefined") {
        path = $(`#template_path_${opts.uuid}`).val();

        $.get(path + "/" + id.val(), function (data) {
          editor.insertContent(data);
          if (editorContent !== editor.getContent()) {
            $(`#template_select_${opts.uuid}`).val("");
            $(`.template-modal-${opts.uuid}`).modal("hide");
          }
        });
      }
    });

    editor.ui.registry.addMenuButton("mybutton", {
      text: "Drug Database",
      fetch: function (callback) {
        var items = [
          {
            type: "menuitem",
            text: "Drug Name",
            onAction: function () {
              editor.insertContent("[{drug_database_name}]");
            },
          },
          {
            type: "menuitem",
            text: "Onset",
            onAction: function () {
              editor.insertContent("[{drug_database_onset}]");
            },
          },
          {
            type: "menuitem",
            text: "Half Life",
            onAction: function () {
              editor.insertContent("[{drug_database_half_life}]");
            },
          },
          {
            type: "menuitem",
            text: "Peak Effect",
            onAction: function () {
              editor.insertContent("[{drug_database_peak_effect}]");
            },
          },
          {
            type: "menuitem",
            text: "Contraindications",
            onAction: function () {
              editor.insertContent("[{drug_database_contraindications}]");
            },
          },
          {
            type: "menuitem",
            text: "Adverse Reactions",
            onAction: function () {
              editor.insertContent("[{drug_database_adverse_reactions}]");
            },
          },
          {
            type: "menuitem",
            text: "Precautions",
            onAction: function () {
              editor.insertContent("[{drug_database_precautions}]");
            },
          },
          {
            type: "menuitem",
            text: "Medical Consideration",
            onAction: function () {
              editor.insertContent("[{drug_database_medical_consideration}]");
            },
          },
          {
            type: "menuitem",
            text: "Mechanism of Action",
            onAction: function () {
              editor.insertContent("[{drug_database_mechanism_of_action}]");
            },
          },
          {
            type: "menuitem",
            text: "Pregnancy Class",
            onAction: function () {
              editor.insertContent("[{drug_database_pregnancy_class}]");
            },
          },
          {
            type: "menuitem",
            text: "Sources",
            onAction: function () {
              editor.insertContent("[{drug_database_sources}]");
            },
          },
        ];
        callback(items);
      },
    });

    // add adult_meds page shortcode
    editor.ui.registry.addButton("adult_meds", {
      text: "Adult Meds",
      tooltip: "Insert an adult medication shortcode",
      onAction: function () {
        editor.insertContent("[{adult_meds}]");
      },
    });

    // add pediatric_meds page shortcode
    editor.ui.registry.addButton("pediatric_meds", {
      text: "Pediatric Meds",
      tooltip: "Insert a pediatric medication shortcode",
      onAction: function () {
        editor.insertContent("[{pediatric_meds}]");
      },
    });

    // editor.ui.registry.addButton("drug_card", {
    //     text: "Drug Card",
    //     tooltip: "Insert Drug Card",
    //     onAction: function () {
    //         $(`.drug-card-modal-${opts.uuid}`).modal("show");
    //     },
    // });
    // $(`.drug-card-modal-${opts.uuid} .save`).on("click", function (e) {
    //     var drugName = $(`#drug_name_${opts.uuid}`);
    //     var pediatric = $(`#pediatric_${opts.uuid}`)
    //     var adult = $(`#adult_${opts.uuid}`)

    //     editor.insertContent('@@[drug_name=' + drugName.val() + '] [pediatric=' + pediatric.is(':checked') + '] [adult=' + adult.is(':checked') +  ']@@');
    // });

    editor.ui.registry.addButton("page_legend", {
      text: "Page Legend",
      tooltip: "Insert Page Legend",
      onAction: function () {
        $(`.legend-modal-${opts.uuid}`).modal("show");
      },
    });
    $(`.legend-modal-${opts.uuid} .save`).on("click", function (e) {
      var checked = $("input[name='legends']:checked")
        .map(function () {
          return this.value;
        })
        .get()
        .join("-");

      const editorContent = editor.getContent();
      path = $(`#legend_path_${opts.uuid}`).val();

      $.get(path + "/?id=" + checked, function (data) {
        editor.insertContent(data);
        if (editorContent !== editor.getContent()) {
          $(`#template_select_${opts.uuid}`).val("");
          $(`.template-modal-${opts.uuid}`).modal("hide");
        }
      });
    });

    editor.ui.registry.addButton("space", {
      text: "Add Space",
      tooltip: "Insert Space",
      onAction: function () {
        x = editor.getContent();
        editor.setContent(x + "<div></div>");
      },
    });

    editor.ui.registry.addButton("insert_space", {
      text: "Insert Space",
      tooltip: "Insert Space",
      onAction: function () {
        // tinymce.activeEditor.dom.addClass('someid', 'someclass');

        // get the node your cursor is in - the one you want to insert after
        var endNode = editor.selection.getEnd();
        var parentNode = tinymce.activeEditor.dom.getParent(endNode, '.drug_box')
        // why doesn't this work for inserting content after this node?
        // var what = tinymce.activeEditor.dom.insertAfter("<p>TEST</p>", parentNode)

        // move the selection after the current node
        editor.selection.select(parentNode);
        editor.selection.collapse(false);

        // insert your content
        editor.selection.setContent("<p>&nbsp;</p>");
        // editor.dom.replace(parentNode, "<p>TEST TEST TEST</p>")
      },
    });

    // add page link button
    editor.ui.registry.addButton("page", {
      text: "Page",
      tooltip: "Insert a link to a page",
      onAction: function () {
        $(`.page-modal-${opts.uuid}`).modal("show");
        $(`.page-modal-${opts.uuid} .save`).click(function (e) {
          // fetch our input nodes - the link text & id/selected page
          var $text = $(`.page-modal-${opts.uuid} input[name='page_title']`);
          var $id = $(`.page-modal-${opts.uuid} input[type='radio']:checked`);

          /**
           * It seems like multiple editors are getting made and the insert content is only
           * finding the correct one on the very last iteration of the loop.
           *
           * We need to compare the content of the WYSIWYG before and after the attempted
           * content insert. If they do not match afterwards, the correct content insert
           * has occured and we can reset all the modal fields
           */
          const editorContent = editor.getContent();
          if (typeof $id.val() !== "undefined") {
            // insert anchor tag to page -- just an <a> tag with the page id as href
            editor.insertContent(
              '<a href="' +
                $id.val() +
                '.html" style="font-family: arial, helvetica, sans-serif; text-decoration: underline; font-size: 10pt; color: black; font-weight: bold;">' +
                $text.val().trim() +
                "</a>"
            );
            if (editorContent !== editor.getContent()) {
              $text.val("");
              $id.prop("checked", false);
            }
          }
        });
      },
    });
    // add changelog page shortcode
    editor.ui.registry.addButton("changelog", {
      text: "Changelog",
      tooltip: "Insert a changelog shortcode",
      onAction: function () {
        editor.insertContent("[{changelog}]");
      },
    });
    // add staff legend page shortcode
    editor.ui.registry.addButton("legend", {
      text: "Staff Legend",
      tooltip: "Insert a staff legend shortcode",
      onAction: function () {
        editor.insertContent("[{staff_legend}]");
      },
    });
    // add cover page shortcode
    editor.ui.registry.addButton("cover", {
      text: "Cover Page",
      tooltip: "Insert a cover page shortcode",
      onAction: function () {
        editor.insertContent("[{coverpage}]");
      },
    });
  };
};

// tool bar for sidebar wysiwyg
window.sidebarToolbar = function () {
  return [
    "paste bold italic strikethrough forecolor backcolor casechange",
    "undo redo",
    "link alignleft aligncenter alignright alignjustify numlist bullist checklist",
    "outdent indent",
    "removeformat code pastetext",
  ].join("|");
};

window.tinyMceOptions = function (opts) {
  return {
    selector: opts.selector,
    content_css: ["/customers/1/protocols/275/custom_css"],
    height: 500,
    toolbar1: opts.sidebar ? sidebarToolbar() : normalToolbar(),
    toolbar2: customToolbar(opts.cover),
    plugins: pluginsToUse(),
    file_picker_types: "file image media",
    automatic_uploads: true,
    image_upload_url: opts.uploadPath,
    image_title: true,
    image_advtab: true,
    paste_webkit_styles: "color font-size",
    paste_merge_formats: true,
    paste_retain_style_properties: "color",
    paste_remove_styles_if_webkit: true,
    font_formats: "Roboto=roboto;",
    file_picker_callback: filePickerCallBack,
    images_upload_handler: imagesUploadHandler(opts.uploadPath),
    setup: setup(opts),
    extended_valid_elements : "script[charset|defer|language|src|type]"
  };
};

//al

window.medicationNotesConfiguration = function () {
  return {
    selector: ".tinymce", // if we have multiple wysiwygs on one page this has to be a class, not id
    toolbar:
      "formatselect | bold italic strikethrough forecolor backcolor | undo redo | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent  | removeformat | code ",
    plugins: ["wordcount,powerpaste,advcode,spellchecker"],
  };
};
